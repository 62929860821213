<template>
    <div  > 
        <div style="font-size:10px;margin-top:10px;" >
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item ><el-link @click="homePage" style="color:#409EFF">{{fldObj['f_home']}}</el-link></el-breadcrumb-item>
                <el-breadcrumb-item ><span style="color:#606266">{{fldObj['f_cate']}}</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div v-if="!v_cond" style="border-bottom:1px solid #ccc;border-top:1px solid #ccc; margin-top:10px;display:flex">
            <div class="moreItem">
                <span style="color:#808080;width:90px;background:#EBEEF5;font-size:14px;font-weight:700;">{{fldObj['f_type']}}：</span>                         
                <span style="margin-left:2px;margin-right:10px;">
                    <el-checkbox  style="height:35px; width:80px;" @change="allSelect" true-label="Y" false-label="N" v-model="checkAll"   >{{fldObj['f_unlimit']}} </el-checkbox>
                </span>   
                <el-checkbox-group v-model="checkList" @change="chkChange">  
                    <div style="display :flex ;width:1045px; flex-wrap:wrap; ">  
                        <div class="chkcss" :title="LANG=='CN'?item.MODELNM:item.EMODELNM"  v-for="(item,index) in typeArr" :key="index">
                            
                            <el-checkbox   :label="item.MODELNO" >
                                <span style=" text-overflow: ellipsis !important;overflow:hidden;white-space:nowrap" > {{LANG=='CN'?item.MODELNM:item.EMODELNM}}  </span>
                            </el-checkbox>
                            
                        </div>   
                    </div>
                </el-checkbox-group>
                <!-- <el-button v-if="moreFlag==='false'" size="small" @click="moreClick" plain style="height:32px;width:60px; margin-top:5px;">更多<Icon type="ios-arrow-down" style="margin-left:3px;"></Icon></el-button>
                <el-button v-if="moreFlag==='true'" size="small" @click="oneClick" plain style="height:32px;width:60px; margin-top:5px;">收起<Icon type="ios-arrow-up" style="margin-left:3px;"></Icon></el-button>                         -->
            </div>
        </div>
        <div style="border-bottom:1px solid #2db7f5; margin-top:20px;display:flex" >
            <el-radio-group v-model="sortby" size="medium" @change="cateChange">
                <el-radio-button label="ALL" >{{fldObj['f_sortby']}}</el-radio-button>
                <el-radio-button label="PRC">{{fldObj['f_price']}}</el-radio-button>
                <el-radio-button label="SHP">{{fldObj['f_ship']}}</el-radio-button>
                <el-radio-button label="STK">{{fldObj['f_stock']}}</el-radio-button>
            </el-radio-group>
            
        </div>
        <div class="rowprd" v-for="(item,index) in matArr" :key="index"  >
            <div style="margin:5px 2px; auto;display:flex;">
                <!-- 产品图片 -->
                <div style="width:110px;" @click="prdList(item.IDSEQ,item.FACIDNO)">
                    <el-tooltip  effect="light"  placement="right" style="margin:4px;cursor:pointer" popper-class="atooltip">
                        <el-image :src='item.DEFPIC'  fit="fill" style="height:80px;width:80px;margin:5px auto"  ></el-image>
                        <template slot="content" >
                            <el-image   fit="fill" style="width:200px;height:200px; text-align:center; " :src="item.DEFPIC"></el-image>        
                        </template>
                    </el-tooltip>
                </div>
                <!-- 供应商型号 -->
                <div style="width:190px;display:flex;flex-direction:column">
                    <span class="divleft"><el-link @click="prdList(item.IDSEQ,item.FACIDNO)" style="font-size:12px;">{{item.KRECOPN}} </el-link></span>
                    <span class="divleft">{{fldObj['f_kreco']}} </span>
                    <span class="divleft">{{fldObj['f_supply']}}:{{item.FACIDNO}} </span>
                </div>
                <!-- 产品描述 -->
                <div style="width:328px;display:flex;flex-direction:column">
                    <div style="display:flex">
                        <div class="divmid">{{fldObj['f_brand']}}:</div> 
                        <div class="fix-row">{{LANG=='CN'?item.SUPNM:item.ESUPNM}} </div>
                    </div>
                    <div style="display:flex">
                        <div class="divmid">{{fldObj['f_type']}}:</div>
                        <div class="fix-row">{{LANG=='CN'?item.MODELNM:item.EMODELNM}} </div>
                    </div>
                    <div style="display:flex">
                        <div class="divmid">{{fldObj['f_desc']}}:</div> 
                        <div class="fix-row" :title="LANG=='CN'?item.MATDESC:item.EMATDESC"> {{LANG=='CN'?item.MATDESC:item.EMATDESC}}</div>
                    </div>
                    <div style="display:flex">
                        <div class="divmid">{{fldObj['f_feature']}}:</div>
                        <div class="fix-row" :title="LANG=='CN'?item.FEATURE:item.EFEATURE"> {{LANG=='CN'?item.FEATURE:item.EFEATURE}}</div>
                    </div>
                </div>
                <!-- 货期库存数量 -->
                <div style="width:160px;display:flex;flex-direction:column">
                    <span class="divleft"> {{fldObj['f_shipdttm']}}: {{LANG=='CN'?item.SHPDTTM:item.ESHPDTTM}}</span>
                    <span class="divspan">{{fldObj['f_bat']}}: 1</span>
                    <span class="divspan"  >{{fldObj['f_stk']}}: {{item.STKQTY}}</span>
                    <span class="divspan"><el-link style="font-size:10px;color:#2db7f5" @click="askPrc(item.SUPNM,item.FACIDNO,item.IDSEQ)">{{fldObj['f_morebuy']}}</el-link></span>
                    <span class="divspan">{{fldObj['f_incre']}}:{{item.ADDQTY}} </span>
                </div>
                <!-- 价格梯度 -->
                <div style="width:70px;display:flex;flex-direction:column; ">
                    <span class="divspan" style="text-align:right">{{fldObj['f_prc']}}</span>
                    <span v-for="(it,ind) in item.children" :key="ind" >
                        <div :class="it.css==='Y'?'divspan':'divspan2'" style="text-align:right">{{it.fqty}} </div>
                    </span>
                </div>
                <!-- 增值税价格 -->
                <div style="width:75px;display:flex;flex-direction:column;">
                    <span class="divspan" style="text-align:right">{{fldObj['f_tax']}}</span>
                    <span v-for="(it,ind) in item.children" :key="ind" >
                        <div :class="it.css==='Y'?'divspan':'divspan2'" style="text-align:right">{{it.cycode}} {{it.prc}} </div>
                    </span>
                </div>
                <!-- 购买数量 -->
                <div style="width:190px;text-align:center;display:flex;flex-direction:column;">
                    <el-input-number @change="buyChange(item.QTY,item.STKQTY,index,item.children )" :min="item.ADDQTY" :step="item.ADDQTY" step-strictly size="small" v-model="item.QTY" style="margin-left:28px;"></el-input-number>
                    <span class="divspan" style="margin-top:10px;margin-left:28px;">
                        <span v-if="item.STKQTY==='0'||item.children.length===0"></span>
                        <span v-else>{{fldObj['f_total']}}: {{item.CYCODE}}{{item.AMT}}</span>
                    </span>
                </div>
                <div style="width:100px;display:flex;flex-direction:column;">
                    <span v-if="item.STKQTY==='0'||item.children.length===0">
                        <el-button   @click="askPrc(item.SUPNM,item.FACIDNO,item.IDSEQ)" type="primary" size="small" style="width:100px;margin:15px auto;" plain>{{fldObj['f_ask']}}</el-button>
                    </span>
                    <span v-else>
                        <el-button :loading="active===index" :disabled="active===index" @click="carJoin(item.IDSEQ,item.QTY,item.AMT,item.FACIDNO,index)" type="primary" size="small" style="width:100px;margin:0px auto;">{{fldObj['f_join']}}</el-button>
                        <el-button :loading="buyit===index" :disabled="buyit===index" @click="buyJoin(item.IDSEQ,item.QTY,item.AMT,item.FACIDNO,index)" type="primary" size="small" style="width:100px;margin:15px auto;" plain>{{fldObj['f_buy']}}</el-button>
                    </span>
                </div>
            </div>
        </div>
 
        <div style="margin-top: 10px;margin-bottom:5px;overflow: hidden">
            <div style="display:flex;justify-content:center;">  
                <Page :total="dataCount" :page-size="rowSize"  :current="current" @on-change="changePage"></Page>
                <span style="color:#606266;margin:7px 10px;font-size:12px;">{{fldObj['f_page']}}</span>
                <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                        <el-option 
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                </el-select>
                <span style="color:#606266;margin:7px 10px;font-size:12px;">{{fldObj['f_goto']}}</span>
                <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
                <span style="color:#606266;margin:7px 5px;font-size:12px;">{{fldObj['f_pg']}}</span>
                <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;">GO</el-button>
            </div>
        </div> 
        <el-dialog :visible.sync="loadWin" width="50px" append-to-body :show-close="false">
            <el-image src="assets/authorize/loading.gif"  ></el-image>   
        </el-dialog> 
    </div>  
</template>
<script>
import { paramBase,paramData ,paramData2} from '../../api/Select'
export default {
    name:"cateGory",
    props: {
        v_cond:{
            type: String,
            required:false,
            default:''
        },
        v_exrt:{type:Number,default:1},
        v_cycode:{type:String},
        v_cyno:{type:String},
    },
    data () {
        return {
            index:1,
            current:1,
            dataCount:0,
            rowSize:10,
            moreFlag:'false',
            disabled:true,
            checkList:[],
            goPage:'',
            options: [{
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 30,
                label: '30'
                }, {
                value: 40,
                label: '40'
                }, {
                value: 50,
                label: '50'
            }],
            LANG:'', 
            matArr:[], //产品明细
            certArr:[],
            loading:false,
            sortby:'ALL',
            isIndeterminate: true,
            isFilter: true,
            typeArr:[], //类别组
            allData:[],
            checkAll: 'Y',
            certCount:0,
            certAll:[], //证书总数量
            fldObj:{},
            pageSize:5, //证书每页显示数量
            loadWin:false,
            active:-1,
            buyit:-1,
            typeNum:0,
        }
    },
    mounted () {   
    
    },
    computed: {
       sumprc: function() {
            return ''
        },   
    },
    components: {
 
    },
    created () {      
        //类别信息  
        this.$axios({   
            method: 'post',
            url:this.$store.state.baseUrl+'auth/login/getBaseData',
            data: {p_table:'V_CATEGORY_LIST'},
        }).then(res=>{ 
            if (res.data.code==='200'){
                this.typeArr =res.data.result
                this.typeNum=res.data.result.length
            }else{
                this.$alert(res.data.result, '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        })  
    },
    watch: {
 
    },
    methods: {
        //字段中、英
        getField(v_lang){
            let that =this
            this.LANG =v_lang
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55511',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] = v_lang=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        homePage(){
            this.$emit('child-home')
        },
        //价格、库存、货期、综合 排序
        cateChange(){          
            let cond= JSON.stringify(this.checkList).replace('[','').replace(']','').replaceAll('"','\'')
            let v_where =''
            if (cond){   
                if (this.sortby ==='PRC') { //按价格
                    v_where =' and modelno in ('+cond+') order by to_number(taxprc)  ' 
                }else if (this.sortby ==='SHP') { //按货期
                    v_where =' and modelno in ('+cond+') order by shpseq  ' 
                }else if (this.sortby ==='STK') { //按库存
                    v_where =' and modelno in ('+cond+') order by to_number(stkqty) desc  '
                }else { //综合
                    v_where =' and modelno in ('+cond+') order by modelno ' 
                }
                this.getMatList(this.v_cond+ v_where)
            }else{
                if (this.checkAll==='Y'){
                    if (this.sortby ==='PRC') { //按价格
                        v_where =' and 1=1 order by to_number(taxprc)  '
                    }else if (this.sortby ==='SHP') { //按货期
                        v_where= ' and 1=1 order by shpseq  '
                    }else if (this.sortby ==='STK') { //按库存
                        v_where =' and 1=1 order by to_number(stkqty) desc  '
                    }else { //综合
                        v_where =' and 1=1 order by modelno '
                    }
                    this.getMatList( this.v_cond+ v_where)
                }else{
                    this.getMatList(' and 1=2')
                }
            }
        },
        //货币转化率
        chgExrt(code,exrt,cond){
            //获取所有产品记录且初始化分页记录         
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getMatList_Prc',
                data: {p_key:'CHKSTFG', p_value:'Y',p_cond:cond},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    this.allData =res.data.result
                    let arr=res.data.result
                    for (let k=0;k<arr.length;k++){
                        let prc=Number(arr[k]['TAXPRC'])/Number( exrt)
                        let amt=Number(arr[k]['QTY'])*prc*(100-arr[k]['DIS01'])/100
                        Object.assign(this.allData[k],{CYCODE:code,TAXPRC:prc.toFixed(2),AMT:amt.toFixed(2)} )
                        let child =arr[k].children
                        for (let j=0;j<child.length;j++){
                            let prc_=Number(arr[k].children[j]['prc'])/Number( exrt)
                            Object.assign(this.allData[k].children[j],{cycode:code,prc :prc_.toFixed(2)})
                        }
                    }
                    this.initPage()     
                }
            }) 
        },
        ///产品明细
        getMatList(cond){
            this.loadWin =true
            //获取所有产品记录且初始化分页记录         
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getMatList_Prc',
                data: {p_key:'CHKSTFG', p_value:'Y',p_cond:cond},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    this.allData =res.data.result
                    this.initPage()     
                }
                this.loadWin=false
            }) 
        },
        //不限类别
        allSelect(){
            if (this.checkAll==='Y'){   
                this.getMatList('')
                this.checkList=[]
            }else{
                this.checkList=[]
                this.getMatList(' and 1=2')
            }
        },
        // 各类别选择事件
        chkChange(){
            if (this.checkList.length!==this.typeNum){
                this.checkAll='N'
            }else{      
                this.checkAll='Y'
            }
            if (this.checkList.length===0){
                this.checkAll='Y'
            }
            let v_where =''
            let cond= JSON.stringify(this.checkList).replace('[','').replace(']','').replaceAll('"','\'')
            if (cond){
                if (this.sortby==='PRC'){ //价格
                    v_where =' and modelno in ('+cond+') order by taxprc' 
                }else if (this.sortby==='SHP'){ //货期
                    v_where=' and modelno in ('+cond+') order by shpseq' 
                }else if (this.sortby==='STK'){ //库存
                    v_where=' and modelno in ('+cond+') order by to_number(stkqty) desc' 
                }else  { //综合
                    v_where=' and modelno in ('+cond+') order by modelno'
                }
                this.getMatList(v_where)
            }else{
                if (this.sortby==='PRC'){ //价格
                    v_where=' and 1=1 order by taxprc' 
                }else if (this.sortby==='SHP'){ //货期
                    v_where=' and 1=1 order by shpseq' 
                }else if (this.sortby==='STK'){ //库存
                    v_where=' and 1=1 order by to_number(stkqty) desc' 
                }else  { //综合
                    v_where=' and 1=1 order by modelno' 
                }
                this.getMatList(v_where)
            }
        },
        //展开---收起
        moreClick(){
            this.moreFlag ='true'
        },
        oneClick(){
            this.moreFlag ='false'
        },
        //产品详情页
        prdList(idseq,idno){
            let routeUrl = this.$router.resolve({
                path: 'prdList',
                query: {lang:this.LANG,facid:idno,idseq:idseq,cycode:this.v_cycode,cyno:this.v_cyno},
            })
            window.open(routeUrl.href, '_blank');
        },
        //跳至页面
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //加入购物车
        carJoin(idseq,qty,amt,facidno,index){
            //先判断有没登录，如没登录就看cookie中有没 uuid
            this.active =index
            let v_userno =this.$cookies.get('v_telno')
            let v_uuid =this.$cookies.get('v_uuid')
            let v_idseq='' ,v_action=''
            if (v_userno){ //有登录
                v_idseq =v_userno
                v_action='Y' 
            }else{
                if (!v_uuid){
                    v_idseq =''
                    v_action='N' 
                }else{
                    v_idseq =v_uuid
                    v_action='Y' 
                }
            }
            let  auth_time = 1;
            var auth_timetimer =  setInterval(()=>{
                auth_time--;
                if( auth_time<=0){
                    this.active = -1;
                    clearInterval(auth_timetimer);
                }
            }, 1000);
            this.$axios({  //登录id,产品id,购物数量，标志，金额
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/carJoinSave',
                data: {p_idseq:v_idseq,p_key:idseq,p_key2:qty,p_action:v_action,p_key3:amt},
            }).then(res=>{ 
                if (res.data.code==='200'){      
                    this.$emit('child-car',res.data.count,facidno)
                    
                    if (!v_uuid){
                        this.$cookies.set('v_uuid',res.data.cookie)
                    }
                }else{
                    console.log(res.data.result)
                }
            }) 
        },
        //直接购买
        buyJoin(idseq,qty,amt,facidno,index){
            //先判断有没登录，如没登录就看cookie中有没 uuid
            this.buyit =index
            let v_userno =this.$cookies.get('v_telno')
            let v_uuid =this.$cookies.get('v_uuid')
            let v_idseq='' ,v_action=''
            if (v_userno){ //有登录
                v_idseq =v_userno
                v_action='Y' 
            }else{
                if (!v_uuid){
                    v_idseq =''
                    v_action='N' 
                }else{
                    v_idseq =v_uuid
                    v_action='Y' 
                }
            }
            let  auth_time = 1;
            var auth_timetimer =  setInterval(()=>{
                auth_time--;
                if( auth_time<=0){
                    this.buyit = -1;
                    clearInterval(auth_timetimer);
                }
            }, 1000);
            this.$axios({  //登录id,产品id,购物数量，标志，金额
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/carJoinSave',
                data: {p_idseq:v_idseq,p_key:idseq,p_key2:qty,p_action:v_action,p_key3:amt},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    if (!v_uuid){
                        this.$cookies.set('v_uuid',res.data.cookie)
                    }
                    let routeUrl = this.$router.resolve({
                        path: "/ordmgt/carlist",
                        query: {lang:this.$store.state.lang },
                    });
                    window.open(routeUrl.href, '_self');    
                }
            }) 
        },
        //询价事件
        askPrc(supnm,facidno,idseq){
            let routeUrl = this.$router.resolve({
                path: '/prd_AskPrc',
                query: {lang:this.LANG,p_supnm:supnm,p_idno:facidno,p_seq:idseq },
            });
            window.open(routeUrl.href, '_blank');
        },
        //变更购买数量时
        buyChange(qty,stkqty,index ,children ){      
            let count=children.length
            let prc_=''
            var qty_=qty
            if (qty>stkqty){
                qty_ =stkqty 
                this.$Message.error('当前库存数量:'+stkqty) 
            }
            //获取阶梯价格
            for (let k=0;k<count;k++){ //初始化
                Object.assign(this.matArr[index].children[k],{css:'Y'} ) 
            }
           
            for (let p=0;p<count;p++){
                if (p+1===count){ //购买数量在数组前、后两个数的比较
                    if (Number(qty_)>=Number(children[p].qty)){
                        prc_ =children[p].prc
                        Object.assign(this.matArr[index].children[p],{css:'N'} ) 
                        break
                    }else{
                        Object.assign(this.matArr[index].children[p],{css:'Y'} ) 
                    }
                }else{
                    if (Number(qty_)>=Number(children[p].qty) && Number(qty_)<Number(children[p+1].qty) ){
                        prc_ =children[p].prc
                        Object.assign(this.matArr[index].children[p],{css:'N'} ) 
                        break
                    }else{
                        Object.assign(this.matArr[index].children[p],{css:'Y'} ) 
                    }
                }
                
            }
            Object.assign(this.matArr[index],{QTY:qty_ ,AMT: Math.floor(qty_*prc_*100)/100} )  
 
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.matArr = this.allData          
            }else{
                this.matArr =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
 
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.matArr = this.allData.slice(_start,_end);
        },
        //热销品牌事件
        webClick(val){
            if (val)
            // window.location.href=val
            window.open(val,'_blank')
        },
        //向左切换
        leftNav(){
            if (this.index===0){
                this.index=Math.ceil(this.certCount/this.pageSize)
            } 
            this.index--
            var _start = ( this.index - 1 ) * this.pageSize;
            var _end = this.index * this.pageSize;
            if (_start<=0){
                this.index=1
                this.certArr = this.certAll.slice(0,this.pageSize);
            }else{
                this.certArr = this.certAll.slice(_start,_end);
            }
 
        },
        //向右切换
        rightNav(){
            this.index++
            var _start = ( this.index - 1 ) * this.pageSize;
            var _end = this.index * this.pageSize;
            if (_end>this.certCount){
                this.certArr = this.certAll.slice(_start,_end);
                this.index=0
            }else{
                this.certArr = this.certAll.slice(_start,_end);
            }
        },
        //菜单事件
        childClick(val){
 
        },
        childPower(val){

        },
 
        goBlank(path,name,param){
            let routeUrl = this.$router.resolve({
                path: path,
                query: {lang:this.LANG,pid:name,param:param},
            });
            if (param==='login'){
                window.open(routeUrl.href, '_self');
            }else if (param==='blank'){
                window.open(routeUrl.href, '_blank');
            }else{
                window.open(routeUrl.href, '_self');
            }
        },
 
 
    }
}
</script>
<style>
    .el-col:hover  {  
        background: #2d8cf0!important; 
    } 
    .el-dropdown-menu{
        top: 103px  !important;
        width:153px !important;
        padding-left:0px !important; 
        margin-right:-48px !important;    
    }
    .el-submenu__title:focus, .el-submenu__title:hover  {  
        background: #dcdee2!important; 
        /* height:40px !important;  */
        width:100% !important;
    }  
    .el-menu-item:hover{  
        background: #2db7f5 !important; 
        color: #fff !important; 
        height:100% !important;  
    }  
    .el-menu-item.is-active {  
        color: #fff !important;  
        background: #409EFF !important;  
    }     
    .el-menu--collapse  {
        width:200px;
        line-height:35px;
    }  
    .el-submenu__title i {
      color: #5cadff;
    }
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }  
    .atooltip{
      border: #1480ce 2px solid !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow::after {
      border-right-color: #1480ce !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow{
        border-right-color: #1480ce !important;
    }  
    .ptooltip{
      border: #ec5778 2px solid !important;
    }
    .el-tooltip__popper[x-placement^=top] .popper__arrow::after {
      border-right-color: #ec5778 !important;
    }
    .el-tooltip__popper[x-placement^=top] .popper__arrow{
        border-right-color: #ec5778 !important;
    }   
    .el-checkbox__inner{
        border-color: #5cadff;
    }  
</style> 
<style scoped lang="less">
    .divcolor{
        background-color: #5cadff;
    }

    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
  .el-icon-arrow-down {
    font-size: 10px;
  }
  .menu-div{
      width:140px; 
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
  }
  .img-div{
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
      line-height:20px;
  }
  .group-css{
      display:flex;
      justify-content:flex-start;
      flex-wrap:wrap;
      width:1250px;
  }
 
  .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .card-div {
        padding: 14px;
        background:#F2F6FC; 
        text-align:middle;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#409EFF;
        font-size:16px;
        // font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }
    .divleft{
        color:#606266;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:3px;
        width:188px;
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:wrap; //禁止换行
    }
    .divspan{
        color:#606266;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:3px;
    }
    .divspan2{
        color:#ec6c82;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:3px;
    }
    .rowprd{
        border:1px solid #E4E7ED; 
        margin-top:20px;
        display:flex
    }
    .rowprd:hover{
        background-color: #f8f8f9;
    }
    .fix-row{
        color:#606266;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:4px;
        width:250px;
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:wrap; //禁止换行
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
  }
  .moreItem{
      line-height:40px;
      display: flex;  
      justify-content: flex-start;
      margin-bottom: 1px;
  }
  .divmid{
        color:#17233d;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        width:60px;
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:wrap; //禁止换行
    }
  .oneItem{
      height: 41px;
      line-height: 41px;
      overflow:hidden;
      display: flex;  
      justify-content: flex-start ;
      margin-bottom: 1px;
  }
  .chkcss{
    width:200px;
    text-align:left;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
    margin-right:8px;
    line-height:40px;
  }
</style>