<template>
    <div > 
 
        <div style="font-size:10px;margin-top:10px;" >
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item ><el-link @click="homePage" style="color:#409EFF">{{fldObj['d_home']}}</el-link></el-breadcrumb-item>
                <el-breadcrumb-item ><span style="color:#606266">{{fldObj['d_sheet']}}</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="border-bottom:1px solid #ccc;border-top:1px solid #ccc; margin-top:20px;display:flex">
            <div :class="moreFlag==='true'?'moreItem':'oneItem'">
                <span style="color:#808080;width:80px;background:#EBEEF5;font-size:14px;font-weight:700;">{{fldObj['d_cate']}}：</span>                         
                <div style="margin-left:2px; ">
                    <el-checkbox  style="height:35px; width:110px;text-align:left;padding-left:15px;" @change="allSelect" true-label="Y" false-label="N" v-model="checkAll"   >{{fldObj['d_limit']}}</el-checkbox>
                </div>
                <el-checkbox-group v-model="checkList" @change="chkChange">                          
                    <div style="display :flex ;width:970px; flex-wrap:wrap;  ">
                        <div class="chkcss" :title="LANG=='CN'?item.MODELNM:item.EMODELNM"  v-for="(item,index) in typeArr" :key="index">    
                            <el-checkbox   :label="item.MODELNO" >
                                <span style=" text-overflow: ellipsis !important;overflow:hidden;white-space:nowrap" > {{LANG=='CN'?item.MODELNM:item.EMODELNM}}  </span>
                            </el-checkbox>  
                        </div>  
                        
                    </div>
                </el-checkbox-group>
                <span v-show="typeArr.length>5" >
                    <el-button v-if="moreFlag==='false'" size="small" @click="moreClick" plain style="height:32px;width:60px; margin-top:5px;">{{fldObj['d_more']}}<Icon type="ios-arrow-down" style="margin-left:3px;"></Icon></el-button>
                    <el-button v-if="moreFlag==='true'" size="small" @click="oneClick" plain style="height:32px;width:60px; margin-top:5px;">{{fldObj['d_hide']}}<Icon type="ios-arrow-up" style="margin-left:3px;"></Icon></el-button>                        
                </span>
            </div>
        </div>
        <div style="color:white;background:#2db7f5;height:40px;display:flex;font-size:14px;margin-top:10px;">
            <div style="width:300px;text-align:center;line-height:40px;">{{fldObj['d_model']}}</div>
            <div style="width:150px;text-align:center;line-height:40px;">{{fldObj['d_oper']}}</div>
            <div style="width:140px;text-align:center;line-height:40px;">{{fldObj['d_stk']}}</div>
            <div style="width:200px;text-align:center;line-height:40px;">{{fldObj['d_made']}}</div>
            <div style="width:180px;text-align:center;line-height:40px;">{{fldObj['d_cate']}}</div>
            <div style="width:255px;text-align:center;line-height:40px;">{{fldObj['d_ex']}}</div>
 
        </div>
        <div style=" min-height:100px" >
            <div class="rowprd" v-for="(item,index) in matArr" :key="index"  >
                <div style="margin-bottom: 5px ;display:flex; ">
                    <!-- 产品型号 -->
                    <div style="width:300px;display:flex;flex-direction:column;" >
                        <span style=" text-align:left;margin-left:15px;color:black">
                            <div style="line-height:25px;">{{fldObj['d_manual']}}<span style="color:#ed4014;font-size:12px;">{{item.CNT}}</span>{{fldObj['d_prod']}}</div>
                            <div v-for="(subitem,subind) in item.SUBS" :key="subind">
                                <el-link v-if="subind<10" :underline="false" @click="prdList(subitem.IDSEQ,subitem.FACIDNO)"><span  style="line-height:20px;">{{subitem.KRECOPN}}【{{subitem.FACIDNO}}】</span></el-link>
                            </div>
                        </span>
                    </div>
                    <!-- 操作 -->
                    <div style="width:150px;display:flex;flex-direction:column;justify-content:center; border-right:1px solid #ccc">
                        <el-link :underline="false" @click="pdfPreview(item.PDFPATH)"><div class="circle"><i class="iconfont icon-pdf" style="font-size:22px;color:white; "></i></div></el-link>
                        <el-link :underline="false" @click="showSheets(item.SUBS)">
                            <div style="margin-top:15px;color:black;margin-bottom:10px;">{{fldObj['d_view']}} <i class="iconfont icon-xiayiye1" style="font-size:10px;"></i></div>
                        </el-link>
                    </div>
                    <!-- 库存 -->
                    <div style="width:140px;display:flex;justify-content:center; ">    
                        <div class="fix-row" :title="LANG=='CN'?item.STKNM:item.ESTKNM"> {{LANG=='CN'?item.STKNM:item.ESTKNM}}</div>
                    </div>
    
                    <!-- 制造商 -->
                    <div style="width:200px;display:flex;justify-content:center;  ">
                        <div class="fix-row" :title="LANG=='CN'?item.SUPNM:item.ESUPNM"> {{LANG=='CN'?item.SUPNM:item.ESUPNM}}</div>
                    </div>
                    <!-- 类别 -->
                    <div style="width:180px;display:flex;justify-content:center; ">
                        <div class="fix-row" :title="LANG=='CN'?item.MODELNM:item.EMODELNM"> {{LANG=='CN'?item.MODELNM:item.EMODELNM}}</div>
                    </div>
                    <!-- 说明 -->
                    <div style="width:255px;display:flex;justify-content:center; ">
                        <div class="fix-row" :title="item.RMK"> {{item.RMK}}</div>
                    </div>
    
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="loadWin" width="50px" append-to-body :show-close="false">
            <el-image src="assets/authorize/loading.gif"  ></el-image>   
        </el-dialog>
        <div style="margin-top: 10px;margin-bottom:5px;overflow: hidden">
            <div style="display:flex;justify-content:center;">  
                <Page :total="dataCount" :page-size="rowSize" :current="current" @on-change="changePage"></Page>
                <span style="color:#606266;margin:7px 10px;font-size:12px;">{{fldObj['d_page']}}</span>
                <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                        <el-option 
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                </el-select>
                <span style="color:#606266;margin:7px 10px;font-size:12px;">{{fldObj['d_goto']}}</span>
                <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
                <span style="color:#606266;margin:7px 5px;font-size:12px;">{{fldObj['d_pg']}}</span>
                <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;">GO</el-button>
            </div>
        </div> 
    </div>  
</template>
<script>
import { paramBase,paramData ,paramData2} from '../../api/Select'
export default {
    name:"technology",
    props: {
        v_cond:{
            type: String,
            required:false,
            default:''
        }
    },
    data () {
        return {
            index:1,
            current:1,
            dataCount:0,
            rowSize:10,
            overY:'hidden',
            moreFlag:'false',
            disabled:true,
            checkList:[],
            goPage:'',
            loadWin:false,
            options: [{
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 30,
                label: '30'
                }, {
                value: 40,
                label: '40'
                }, {
                value: 50,
                label: '50'
            }],
            LANG:'', 
            matArr:[], //产品明细
            certArr:[],
 
            sortby:'ALL',
            isIndeterminate: true,
            isFilter: true,
            typeArr:[], //类别组
            allData:[],
            fldObj:{},
            checkAll: 'Y',
            certCount:0,
            certAll:[], //证书总数量
            pageSize:5, //证书每页显示数量
            prdArr:[], //查看产品数组
            active:-1,
            buyit:-1,
            typeNum:0,
        }
    },
    mounted () {   
              
    },
    computed: {
       sumprc: function() {
            return ''
        },   
    },
    components: {
 
    },
    created () {
 
        //类别信息  
        this.$axios({   
            method: 'post',
            url:this.$store.state.baseUrl+'auth/login/getBaseData',
            data: {p_table:'V_DATASHEET_LIST'},
        }).then(res=>{ 
            if (res.data.code==='200'){
                this.typeArr =res.data.result
                this.typeNum=res.data.result.length
            }else{
                this.$alert(res.data.result, '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        })  
    },
    watch: {
 
    },
    methods: {
        //界面字段语言设置
        getField(){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55515',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =that.LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        //查看产品
        showSheets(subArr){
            this.prdArr =[]
            for(let k=0;k<subArr.length;k++){
                if (subArr.length<20){
                    this.prdArr.push(JSON.stringify(subArr[k].IDSEQ))
                }
            }
            let prdCond =JSON.stringify(this.prdArr).replace('[','').replace(']','').replaceAll('"','\'')
            let routeUrl = this.$router.resolve({
                path: '/dataSheet/prdList',
                query: {lang:this.LANG,p_where: ' and idseq in ('+prdCond+')' ,p_num:subArr.length<20?subArr.length:20 },
            });
            window.open(routeUrl.href, '_blank');   
        },
        pdfPreview(fileUrl){
            //全屏显示
            let routeUrl = this.$router.resolve({
                path: '/fullPreview',
                query: {lang:this.LANG,fileUrl: fileUrl },
            });
            window.open(routeUrl.href, '_blank'); 
        },
        homePage(){
            this.$emit('child-home')
        },
        ///产品数据手册明细
        getMatList(cond){
            this.loadWin =true
            //获取所有产品记录且初始化分页记录         
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getDataSheet',
                data: {p_cond:cond},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    //console.log(JSON.stringify(res.data.result))
                    this.allData =res.data.result
                    this.initPage() 
                    this.loadWin=false
                }
            }) 
        },
        //不限类别
        allSelect(){
            if (this.checkAll==='Y'){   
                this.getMatList('')
                this.checkList=[]
            }else{
                this.checkList=[]
                this.getMatList(' and 1=2')
            }
        },
        // 各类别选择事件
        chkChange(){
            if (this.checkList.length!==this.typeNum){
                this.checkAll='N'
            }else{      
                this.checkAll='Y'
            }
            if (this.checkList.length===0){
                this.checkAll='Y'
            }
            let cond= JSON.stringify(this.checkList).replace('[','').replace(']','').replaceAll('"','\'')
            if (cond){
                this.getMatList(' and modelno in ('+cond+')')
            }else{
                 this.getMatList(' and 1=1')
            }
        },
        //展开---收起
        moreClick(){
            this.moreFlag ='true'
            this.overY ='auto'
        },
        oneClick(){
            this.moreFlag ='false'
            this.overY='hidden'
        },
        //产品详情页
        prdList(idseq,idno){
            let routeUrl = this.$router.resolve({
                path: 'prdList',
                query: {lang:this.LANG,facid:idno,idseq:idseq},
            })
            window.open(routeUrl.href, '_blank');
        },
        //跳至页面
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
 
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.matArr = this.allData          
            }else{
                this.matArr =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
 
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.matArr = this.allData.slice(_start,_end);
        },
        //热销品牌事件
        webClick(val){
            if (val)
            // window.location.href=val
            window.open(val,'_blank')
        },
 
        //菜单事件
        childClick(val){
 
        },
        childPower(val){

        },
 
        goBlank(path,name,param){
            let routeUrl = this.$router.resolve({
                path: path,
                query: {lang:this.LANG,pid:name,param:param},
            });
            if (param==='login'){
                window.open(routeUrl.href, '_self');
            }else if (param==='blank'){
                window.open(routeUrl.href, '_blank');
            }else{
                window.open(routeUrl.href, '_self');
            }
        },
 
 
    }
}
</script>
<style>
    .el-col:hover  {  
        background: #2d8cf0!important; 
    } 
    .el-dropdown-menu{
        top: 103px  !important;
        width:153px !important;
        padding-left:0px !important; 
        margin-right:-48px !important;    
    }
    .el-submenu__title:focus, .el-submenu__title:hover  {  
        background: #dcdee2!important; 
        /* height:40px !important;  */
        width:100% !important;
    }  
    .el-menu-item:hover{  
        background: #2db7f5 !important; 
        color: #fff !important; 
        height:100% !important;  
    }  
    .el-menu-item.is-active {  
        color: #fff !important;  
        background: #409EFF !important;  
    }     
    .el-menu--collapse  {
        width:200px;
        line-height:35px;
    }  
    .el-submenu__title i {
      color: #5cadff;
    }
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }  
    .atooltip{
      border: #1480ce 2px solid !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow::after {
      border-right-color: #1480ce !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow{
        border-right-color: #1480ce !important;
    }  
    .ptooltip{
      border: #ec5778 2px solid !important;
    }
    .el-tooltip__popper[x-placement^=top] .popper__arrow::after {
      border-right-color: #ec5778 !important;
    }
    .el-tooltip__popper[x-placement^=top] .popper__arrow{
        border-right-color: #ec5778 !important;
    }   
    .el-checkbox__inner{
        border-color: #5cadff;
    }  
</style> 
<style scoped lang="less">
    .divcolor{
        background-color: #5cadff;
    }

    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
  .el-icon-arrow-down {
    font-size: 10px;
  }
  .menu-div{
      width:140px; 
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
  }
  .img-div{
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
      line-height:20px;
  }
  .group-css{
      display:flex;
      justify-content:flex-start;
      flex-wrap:wrap;
      width:1250px;
  }
 
  .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .card-div {
        padding: 14px;
        background:#F2F6FC; 
        text-align:middle;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#409EFF;
        font-size:16px;
        // font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }
    .divleft{
        font-size:16px;
        text-align:left;
        margin-top:10px;
        color:#606266;
        margin-left:10px;
    }
    .divspan{
        color:#606266;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:3px;
    }
    .divspan2{
        color:#ec6c82;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:3px;
    }
    .rowprd{
        border-bottom:1px solid #ccc; 
        margin-top:20px;
        display:flex
    }
    .rowprd:hover{
        background-color: #f8f8f9;
    }
    .fix-row{
        color:#606266;
        text-align:left;
        font-size:10px;
        margin-top:3px;
        margin-right:8px;
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:wrap; //禁止换行
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
  }
  .moreItem{
      line-height:40px;
      display: flex;  
      justify-content: flex-start;
      margin-bottom: 1px;
  }
  .oneItem{
      height: 41px;
      line-height: 41px;
      overflow:hidden;
      display: flex;  
      justify-content: flex-start ;
      margin-bottom: 1px;
  }
  .circle {
        background: #2db7f5;
        // border: 0.1875em solid #0F1C3F;
        border-radius: 50%;
        // box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);
        height: 3em;
        width: 3em;
  }
  .chkcss{
    width:175px;
    text-align:left;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
    margin-right:8px;
    line-height:40px;
  }
</style>