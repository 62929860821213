<template>
    <div >      
        <template> 
            <BackTop :height="100" :bottom="60" >
                <div class="topback"><Icon type="ios-arrow-up" class="topfont"/>{{fldObj['m_top']}}</div>
            </BackTop>
        </template>
        <el-container  >  
            <fixRight ref="refright"/> 
            <el-header :style="{position: 'fixed', width: '100%','z-index':999,height:'100px'}">
                 <mainHead @child-menuSelect="menuClick" @child-power="childPower" @child-crcy='crcyChg' ref="headbar" style="z-index:999"></mainHead>
            </el-header>
            
            <el-main  :style=" {'margin-top':'125px',width:'1270px','margin-left':'auto','margin-right':'auto'} "  >          
                <powerList v-if ="powerFlag===true" @child-powerhome="homePage" ref="refpower"></powerList>
                <category v-else-if ="cateFlag===true"  ref="refCate" :v_cyno="cyno" :v_cycode="cycode" :v_exrt="exrt" v-bind:v_cond="propNext" @child-home="homePage" @child-car="carJoin"></category>
                <technology v-else-if ="techFlag===true" ref="refTech" v-bind:v_cond="propTech" @child-home="homePage" ></technology>
                <solution v-else-if ="mthFlag===true" @child-home="homePage" ref="refmth"></solution>
                <div v-else>
                <el-row>   
                    <el-col :span="4" style="background: #fff !important">
                        <div style="height:320px;overflow-y:auto;overflow-x:hidden; " >
                            <el-menu  :collapse="true">                     
                                <template v-for="(item, index) in catArr"> 
                                    <el-submenu  :index="item.subno" :key="index" >
                                        <template slot="title" >
                                            <div    style="display:flex;width:200px" >
                                                <div style="width:175px; " class="menu-div" :title="LANG=='CN'?item.cname:item.ename">{{LANG=='CN'?item.cname:item.ename}}   </div>
                                                <div> <i class="el-icon-arrow-right"></i> </div>
                                            </div>
                                        </template>
                                        <div  style="display:flex; max-width:765px;flex-wrap:wrap;max-height:415px;overflow-y:auto " >     
                                            <div v-for="(subitem, ind) in item.subs" :key="ind" style="display:flex;flex-wrap:wrap;width:250px;overflow-y:auto;height:200px;margin-right:3px; "  >     
                                                <div style="display:flex;flex-direction:column">
                                                    <el-link @click="typeFind(subitem.subno,LANG=='CN'?subitem.cname:subitem.ename,'two')" :underline="false" >
                                                        <div  class="submenu-row" :title="LANG=='CN'?subitem.cname:subitem.ename">
                                                            {{LANG=='CN'?subitem.cname:subitem.ename}} 
                                                        </div>
 
                                                    </el-link>
                                                    <div v-for="(titem, tind) in subitem.subs" :key="tind" style="line-height:35px;height:35px;padding-left:6px;">
                                                        <div  :index="titem.subno"  :title="LANG=='CN'?titem.cname:titem.ename">
                                                            <el-link :underline="false" style="line-height:35px;height:35px; " @click="typeFind(titem.subno,LANG=='CN'?subitem.cname:subitem.ename+'--'+LANG=='CN'?titem.cname:titem.ename,'three')">
                                                                <div    class="submenu-div">{{LANG=='CN'?titem.cname:titem.ename}}</div>
                                                            </el-link>
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-submenu>
                                </template>                    
                            </el-menu>            
                        </div>
                    </el-col>  
                    <el-col :span="20" style="height:322px;background: #fff !important ">  
  
                        <el-carousel  height="320px" style="margin-top:0px;">
                            <el-carousel-item  v-for="(item, index) in banArr" :key="index"  >
                                <el-image   :src='item.IMGPATH' fit="fill" style="width:100%;height:100%; " ></el-image>
                            </el-carousel-item>
                        </el-carousel>
   
                    </el-col>
                </el-row> 
                <!-- 热销产品 -->
                <div style="margin-top:3px;  ">
                    <table  >
                        <tr>
                            <td  style="width:0;border:3px solid #409EFF; "> </td>
                            <td  style="color:#303133;font-weight:600 ;font-size:1.3em;"><span style="margin-left:10px;">{{fldObj['m_hot']}}</span></td>
                        </tr>
                    </table>
                </div>
                <div class="group-css">
                    <div style="margin-top:10px; " v-for="(item, index) in hotArr" :key="index"  >
                        <!-- <el-link :underline="false" > -->
                            <el-card :body-style="{ padding: '0px',width:'192px',height:'200px' }"  >                    
                                <div style="height:120px">
                                    <el-image @click="prdList(item.MATIDSEQ,item.IDNO)"  :src='item.IMGPATH'  fit="scale-down" style="width:190px;height:118px;padding:1px;" class="card_hover"></el-image>
                                </div>
                                <div class="card-div">   
                                    <span style="font-size:12px;color:#999;line-height:20px;">{{ item.IDNO}}</span> 
                                    <div class="img-div">
                                        <span style="font-size:12px;color:#999" :title="LANG=='CN'?item.MATDESC:item.EMATDESC">{{LANG=='CN'?item.MATDESC:item.EMATDESC}}</span> 
                                    </div>
                                    <div class="img-div">
                                        <span style="font-size:14px;color:red"> {{item.CYCODE}} {{ item.TAXPRC}}</span> 
                                    </div>
                                </div>
                            </el-card>
                        <!-- </el-link> -->
                    </div>
                </div>
                <!--原厂对接--> 
                <div style="margin-top:15px; ">
                    <table  >
                        <tr>
                            <td  style="width:0;border:3px solid #409EFF; "> </td>
                            <td  style="color:#303133;font-weight:600 ;font-size:1.3em;"><span style="margin-left:10px;">{{fldObj['m_factory']}}</span></td>
                        </tr>
                    </table>
                </div>
                <div class="group-css">
                    <div style="margin-top:10px; " v-for="(item, index) in ftyArr" :key="index"  >
                        <el-card :body-style="{ padding: '0px',width:'260px',height:'200px' }" class="cardhover">                    
                            <el-image   :src='item.IMGPATH'  fit="fill" style="width:100%;height:160px;padding-top:10px;" ></el-image>
                            <div style="padding: 9px;background:#EBEEF5; text-align:middle">
                                <span style="font-size:12px;color:#999">{{LANG=='CN'?item.CNAME:item.SUPSENM}}</span> 
                            </div>
                        </el-card>
                    </div>
                </div>
                <!-- 授权厂商 -->
                <div style="margin-top:15px; ">
                    <table  >
                        <tr>
                            <td  style="width:0;border:3px solid #409EFF; "> </td>
                            <td  style="color:#303133;font-weight:600 ;font-size:1.3em;"><span style="margin-left:10px;">{{fldObj['m_purchase']}}</span></td>
                        </tr>
                    </table>
                </div>
                <div class="group-css">
                    <el-image :src="advertPath" fit="fill" style="width:1230px;height:120px;margin-top:8px;"></el-image>
                    <div style="margin-top:10px; " v-for="(item, index) in authArr" :key="index"  >
                        <el-card :body-style="{ padding: '0px',width:'260px',height:'200px' }" class="cardhover">                    
                            <el-image   :src='item.IMGPATH'  fit="scale-down" style="width:100%;height:160px;padding-top:10px;" ></el-image>
                            <div style="padding: 9px;background:#EBEEF5; text-align:middle">
                                <span style="font-size:12px;color:#999">{{LANG=='CN'?item.CNAME:item.SUPSENM}}</span> 
                            </div>
                        </el-card>
                    </div>
                </div>
                </div>
            </el-main>
            <el-footer  class='footer-css'>
                <mainFoot ref="pgfoot"/>
            </el-footer>                  
        </el-container> 
 
        <div   style="width:250px"   class="askcss" > 
            <div  :style="{'background-image': 'url(/assets/basedict/headbar.png)' ,'height':'40px' }">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;font-weight:600;color:#600030">{{fldObj['m_board']}}</span>
                <el-link v-if="noteVisible" :underline="false" @click="noteClick" style="float:right;margin-top:16px;margin-right:10px;width:15px;border:3px solid #600030"></el-link>
                <el-link v-if="!noteVisible" :underline="false" @click="noteClick" style="float:right;margin-top:14px;margin-right:10px;width:15px;height:15px;border:2px solid #600030"></el-link>
            </div>
            <span v-if="noteVisible">
                <span v-if="toogle">
                    <div style="line-size:30px;height:30px;padding-left:10px;margin-top:10px;"><i class="web-font" >{{fldObj['m_logo']}}</i></div>
                    <div style=" padding:5px 2px;">
                        <el-input type="textarea" maxlength="300" :rows="4" :show-word-limit="true" v-model="notermk"  :placeholder="fldObj['m_note']"></el-input>
                    </div>
                    <!-- <div v-if="tipVisible" style=" padding:5px 10px;color:red">{{tipVal}}</div> -->
                    <el-input v-model="notetelno" :placeholder="fldObj['m_phone']" style=" padding:5px 2px;" >                      
                        <template slot ="prepend" ><i class="iconfont icon-shouji" style="color:#409EFF"></i></template>
                    </el-input>
    
                    <el-input v-model="notecode" :placeholder="fldObj['m_code']" style=" padding:5px 2px;" >                      
                        <template slot ="prepend" ><i class="iconfont icon-envelope" style="color:#409EFF"></i></template>
                        <template slot ="append" ><el-button :disabled="!sendAuthCode" @click="getAuthCode" style="color:#409EFF">{{fldObj['m_get']}}</el-button></template>
                    </el-input>
                    <div style="line-size:30px;height:40px;padding :5px 10px;margin-bottom:5px;">               
                        <el-button style="width:100%;" size="small" type="primary" @click="askDailog">
                            <i class="iconfont icon-daochu1" style="font-size:16px;margin-right:10px;"></i>
                            <span style="font-size:14px;font-weight:500">{{fldObj['m_send']}}</span>
                        </el-button>
                    </div>
                </span>
                <span v-if="!toogle">
                    <div style="line-size:30px;height:30px;padding-left:10px;margin-top:10px;color:#409eff">
                        <i class="web-font" >{{fldObj['m_thank']}}</i>
                    </div>
                    <div style="line-size:30px;height:30px;padding-left:10px;margin-top:10px;color:#409eff">
                        <i class="web-font" >{{fldObj['m_contact']}}</i>
                    </div>
                    <div style="line-size:30px;height:40px;padding :5px 10px;margin-bottom:5px;margin-top:165px;">               
                        <el-button style="width:100%;" size="small" type="primary" @click="askClose">
                            <i class="iconfont icon-cuowukongxin" style="font-size:16px;margin-right:10px;"></i>{{fldObj['m_close']}}
                        </el-button>
                    </div>
                </span>
            </span>
        </div> 
        <el-dialog :visible.sync="dialogVisible" width="450px" append-to-body :show-close="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['m_prompt']}}</span>
            </div>
            <div  style="line-size:30px; display:flex" >
                <i class="iconfont icon-tishi1" style="color:#409eff;font-size:30px;margin-right:10px;"></i>
                <div style="line-height:30px;margin-top:6px ">{{prompt}} </div>
            </div>
            <span slot="footer"  :style="{'height':'40px' }">
                <el-button size="small" type="primary" @click="dialogVisible = false">{{fldObj['m_sure']}}</el-button>
            </span>
        </el-dialog>
    </div>  
</template>
<script>
import {getTabColOrData} from '../../api/user' 
import { paramBase,paramData ,paramData2,paramDataOrder} from '../../api/Select'
import fixRight from '@/components/forms/fixRight.vue'
import mainHead from '@/components/forms/main_Head.vue'
import mainFoot from '@/components/forms/main_Foot.vue'
import powerList from '../ordmgt/powerList.vue'
import category from './category.vue'
import technology from './technology.vue'
import solution from './solution.vue'
export default {
    metaInfo() {
        return {
            title: this.setForm['title'] , // set a title
            meta: [{             // set meta
                name: 'keyWords',
                content: this.setForm['keyword']
            },
                {
                name: 'description',
                content: this.setForm['describe']
                }],
            link: [{ // set link
                rel: 'asstes',
                href: this.setForm['link']
            }]
        }
    },
    name:"prd_catlist",
    data () {
        return {
            toogle:true,
            LANG: 'EN',
            dialogVisible:false,
            prompt:'',
            exrt:1,
            cycode:'￥', //￥
            cyname:'CNY',
            cyno:'',
            tipVal:'',
            propNext:'',
            noteVisible:true,
            sendAuthCode:true,
            auth_time: 0, /*倒计时 计数器*/
            headFlag:'true', //登录、注册按钮显示标志          
            catArr:[], //电工电气最低层 类别
            hotArr:[],
            powerFlag:false, //电源电器标志
            banArr:[],
            cateFlag: false, //全部分类标志
            propTech:'',
            btnMsg:'',
            fldObj:{},
            tempArr:[],
            authArr:[], //授权图片
            ftyArr:[], //对接工厂  
            techFlag: false, //数据手册标志
            mthFlag: false, //解决方案标志
            username:'',     
            notermk:'',     
            notecode:'',
            notetelno:'',
            area:'305',
            v_user:this.$cookies.get('v_telno'),
            v_pwd:this.$cookies.get('v_enpwd'),
            v_url:this.$store.state.nologinUrl, //api请求路径 
            advertPath:'',
            setForm:{
                title:'',   //抬头
                keyword:'',  //关键字
                describe:'', //内容描述
                link:'',     //链接地址
            },
 
        }    
    },
    mounted () { 
        // 页面传过来的语言
        if (this.$route.query.lang){
            this.LANG =this.$route.query.lang
            this.$refs.headbar.getField()
            this.$refs.headbar.chklang=this.LANG
        } 
        // 从品牌商返回到 电源器件标签
        if (this.$route.query.param ==='power' ){           
            this.powerFlag=true
            this.$refs.headbar.powerFlag=true
        }else if (this.$route.query.param ==='category' ){           
            this.cateFlag=true
            this.$refs.headbar.catFlag=true
            this.$nextTick(()=>{
                this.$refs.refCate.getField(this.LANG)
            })  
        }else if (this.$route.query.param ==='technology' ){           
            this.techFlag=true
            this.$refs.headbar.techFlag=true
            this.$nextTick(()=>{
                this.$refs.refTech.LANG=this.LANG
                this.$refs.refTech.getField()
            }) 
        }else if (this.$route.query.param ==='solution' ){           
            this.mthFlag=true
            this.$refs.headbar.mthFlag=true
            this.$nextTick(()=>{
                this.$refs.refmth.LANG=this.LANG
                this.$refs.refmth.getField()
            }) 
        }
        
        // 子页面的语言设定
        this.$refs.headbar.chklang=this.LANG
        this.$refs.headbar.getField()
        this.$refs.pgfoot.getField(this.LANG)
        this.$refs.refright.getField(this.LANG)
        
        //厂牌
        paramData('imgty','ADVERT','V_BS_IMG').then((res)=>{
            this.advertPath =res.data.result[0].IMGPATH
        }); 
        
        //授权厂商
        paramDataOrder('imgty','AUTH','V_BS_IMG',' order by sortby,lstseq').then((res)=>{ 
            this.authArr =res.data.result
        });
        //对接工厂
        paramDataOrder('imgty','FTY','V_BS_IMG',' order by sortby,lstseq').then((res)=>{ 
            this.ftyArr =res.data.result
        });
        //热销产品
        paramDataOrder('','','V_MAT_HOT',' order by sortby,lstseq').then((res)=>{ 
            this.hotArr =res.data.result
        });
        //BANNER
        paramDataOrder('imgty','BANNER','V_BS_IMG',' order by sortby,lstseq').then((res)=>{ 
            this.banArr =res.data.result
        });
        this.getField()
        //登录窗口传来
        if (this.$route.query.param==='login'){      
            this.$refs.headbar.username=this.$route.query.userno
            this.$refs.headbar.headFlag ='false'
        }
        this.cyno ='19677'
    },
    components: {
        fixRight,
        mainHead,
        mainFoot,
        powerList,  //热销品牌
        category,
        technology,
        solution,
    },
    created () {
        if(this.$cookies.get('v_telno') ){
            this.username=this.$cookies.get('v_telno')
            this.headFlag ='false'          
        }
        this.seoInit()
        this.getCatList()  //类目数据   
 
    },
    watch: {
 
    },
    methods: {
         
        // seo 初始化
        seoInit(){
            paramDataOrder('path','/','v_SEOSET','').then((res)=>{ 
                this.setForm['title'] =this.LANG=='CN'?res.data.result[0].TITLE:res.data.result[0].ETITLE
                this.setForm['link'] ='https://www.ipskre.com/login?lang='+this.LANG
                this.setForm['keyword'] =this.LANG=='CN'?res.data.result[0].KEYWORD:res.data.result[0].EKEYWORD
                this.setForm['describe'] =this.LANG=='CN'?res.data.result[0].DESCRIBE:res.data.result[0].DESCRIBE
            })
 
        },
        //货币转化率
        crcyChg(obj){
            paramDataOrder('crcy',obj.crcy,'V_crcy',' order by adddttm desc').then((res)=>{ 
                this.exrt =res.data.result[0].EXRT
                this.cycode=obj.sname
                this.cyname=obj.cname
                this.cyno =obj.crcy
                paramDataOrder('','','V_MAT_HOT',' order by sortby,lstseq').then((res)=>{ 
                    for(let k=0;k<res.data.result.length;k++){
                        let prc=Number(res.data.result[k]['TAXPRC'])/Number(this.exrt)
                        Object.assign(this.hotArr[k],{CYCODE:obj.sname,TAXPRC:prc.toFixed(2)})
                    }
                })  
                this.$nextTick(()=>{
                     this.$refs.refCate.chgExrt(obj.sname,this.exrt,'')
                })
            })
        },
        //产品详情页
        prdList(idseq,idno){
            let routeUrl = this.$router.resolve({
                path: '/prdList',
                query: {lang:this.LANG,facid:idno,idseq:idseq,cyno:this.cyno,cycode:this.cycode,cyname:this.cyname},
            })
            window.open(routeUrl.href, '_blank');
        },
        askClose(){
            this.toogle =true
            this.notetelno=''
            this.notecode=''
            this.notermk=''
        },
        //获取验证码
        getAuthCode() {
            if (!this.notetelno){
                this.dialogVisible=true
                this.prompt =this.fldObj['m_telno']
            }else {
                let area_ =this.area==='305'?'china':'foreign'
                //消息验证
                this.$axios({
                    method:'post', 
                    url: this.$store.state.baseUrl+'auth/login/sendMessage' ,
                    data:{  //get这里应为params //请求参数
                        telno :this.notetelno,
                        area :area_,
                        areano:this.area
                    },
                }).then(res =>{
                    if (res.data.code === 'OK'){
                        this.sendAuthCode = false;
                        this.auth_time = 60;
                        let v_obtain =this.fldObj['m_get']
                        var auth_timetimer =  setInterval(()=>{
                            this.auth_time--;
                            this.fldObj['m_get']=this.auth_time+'(s)'
                            if(this.auth_time<=0){
                                this.sendAuthCode = true;
                                this.fldObj['m_get']=v_obtain
                                clearInterval(auth_timetimer);
                            }
                        }, 1000);
                    }else{
                        this.dialogVisible=true
                        this.prompt =res.data.message
                    }
                }) 
            }
        },
        //发送
        askDailog(){
            let vm=this
            if (!this.notermk){
                this.dialogVisible=true
                this.prompt =this.fldObj['m_note']
            } else if (!this.notecode){
                this.dialogVisible=true
                this.prompt =this.fldObj['m_code']
            }else if (!this.notetelno){
                this.dialogVisible=true
                this.prompt = this.fldObj['m_telno']
            }else {
                //短信验证码 10分钟有效 及合法性判断
                this.$axios({
                    method:'post', 
                    url: this.$store.state.baseUrl+'auth/login/validMessage' ,
                    data:{  //get这里应为params //请求参数
                        telno :this.notetelno,
                        code :this.notecode,
                    },
                }).then(res =>{
                    if (res.data.code === '200'){
                        this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                            method: 'post',
                            url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.v_user+'&password='+vm.v_pwd,
                            data: {p_json:JSON.stringify({IDSEQ:'',TELNO:this.notetelno,RMK:this.notermk}), p_table:'bs_note',frmid:'0'},
                        }).then(res=>{ 
                            if (res.data.code==='200'){
                                this.toogle=false  
                            }else{
                                this.dialogVisible=true
                                this.prompt =res.data.message
                            }
                        })
                           
                    }else{
                        this.dialogVisible=true
                        if (res.data.code=='301') {
                            this.prompt =this.fldObj['m_telchk']
                        }else if (res.data.code=='302') {
                            this.prompt =this.fldObj['m_reget']
                        }else {
                            this.prompt =res.data.message
                        }   
                    }
                }) 

            }
        },
        noteClick(){
            this.noteVisible=!this.noteVisible
        },
        typeFind(code,val,type){
            this.$router.push({
                path: "/typeFind",
                //name:'typeFind',//params传参，需要使用name,否则取不到，对应路由配置的name
                query: {type:type,typeName:val,typeNo:code,lang:this.LANG,cyno:this.cyno,cycode:this.cycode,cyname:this.cyname },
            });
 
        },
        //加入购物车
        carJoin(cnt,facidno){
            this.$refs.fixright.setCar( cnt,facidno)
        },
        // 从全部分类 返回到首页
        homePage(){
            this.cateFlag =false
            this.powerFlag =false
            this.techFlag =false
            this.mthFlag =false
            this.$refs.headbar.catFlag=false
            this.$refs.headbar.powerFlag=false
            this.$refs.headbar.techFlag=false
            this.$refs.headbar.mthFlag=false
        },
        //工具条 首页
        childPower(){
            this.powerFlag=false
            this.cateFlag=false
            this.techFlag=false
            this.mthFlag=false
        },
 
        menuClick(data,val){
             //品牌
             if (data ==='power'){
                 this.powerFlag=true
                 this.cateFlag=false
                 this.techFlag =false
                 this.mthFlag =false
                 this.$nextTick(()=>{
                     this.$refs.refpower.LANG=this.LANG
                     this.$refs.refpower.getField(this.LANG)
                 })
             }else  if (data ==='category'){ //全部分类
                 this.cateFlag=true
                 this.powerFlag=false
                 this.techFlag =false
                 this.mthFlag =false
                 this.cycode=val
                 this.$nextTick(()=>{
                     this.$refs.refCate.chgExrt(val,this.exrt,'')
                     this.$refs.refCate.getField(this.LANG)
                     this.$refs.refCate.getMatList('') 
                 })
                 
             }else  if (data ==='prdsrch'){ //产品搜索
                 this.cateFlag=true
                 this.powerFlag=false
                 this.techFlag =false
                 this.mthFlag =false
                 this.propNext=val    
                 setTimeout(() => {
                    this.$refs.refCate.getField(this.LANG)
                    this.$refs.refCate.getMatList(val)
                 }, 200);            
             }else  if (data ==='technology'){ //数据手册
                 this.cateFlag=false
                 this.powerFlag=false
                 this.techFlag =true
                 this.mthFlag =false
                 setTimeout(() => {
                    this.$refs.refTech.LANG=this.LANG
                    this.$refs.refTech.getField()
                    this.$refs.refTech.getMatList(val)
                 },200)
             }else  if (data ==='techsrch'){ //数据手册搜索
                 this.cateFlag=false
                 this.powerFlag=false
                 this.techFlag =true
                 this.mthFlag =false
                 this.propTech=val
                 setTimeout(() => {
                    this.$refs.refTech.LANG=this.LANG
                    this.$refs.refTech.getField()
                    this.$refs.refTech.getMatList(val)
                 },200)
             }else  if (data ==='solution'){ //解决方案
                 this.cateFlag=false
                 this.powerFlag=false
                 this.techFlag =false
                 this.mthFlag =true
                 setTimeout(() => {
                    this.$refs.refmth.LANG=this.LANG
                    this.$refs.refmth.getField(this.LANG)
                 },200)
             }else  if (data ==='lang'){ //语言切换
                 this.LANG =val
                 this.getField()
                 this.seoInit()
                 setTimeout(() => {
                    this.$refs.pgfoot.getField(this.LANG)
                    this.$refs.refright.getField(this.LANG) 
                    if (this.cateFlag){
                      this.$refs.refCate.getField(this.LANG)
                    }
                    if (this.powerFlag ){
                        this.$refs.refpower.LANG=this.LANG
                        this.$refs.refpower.getField(this.LANG)
                    }
                    if (this.techFlag){        
                        this.$refs.refTech.LANG=this.LANG
                        this.$refs.refTech.getField()          
                    }
                    if (this.mthFlag){        
                        this.$refs.refmth.LANG=this.LANG
                        this.$refs.refmth.getField()          
                    }
                    
                 }, 100);
             }   
        },
        getField(){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55510',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =that.LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        //左侧菜单明细
        getCatList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getThreeMenu' ,
                data: {},
            }).then(res =>{
                this.catArr =res.data  
            })
        },
        
    }
}
</script>
<style  lang="less">
    .el-carousel {  
        .el-carousel__button {// 指示器按钮
            width: 14px;
            height: 14px;
            border: none;
            border-radius: 50%;
            background-color: rgb(9, 143, 226);
        }
        .is-active .el-carousel__button {// 指示器激活按钮
            background: #e9378a;
        }
    }
    .atooltip{
      border: #1480ce 1px solid !important;
    }
 
    .el-tooltip__popper[x-placement^=bottom] .popper__arrow{
        border-right-color: #1480ce !important;
        border-left-color: #1480ce !important;
    }
    .el-col:hover  {  
        background: #409eff!important; 
    } 
    .el-dropdown-menu{
 
        padding-left:0px !important; 
        margin-right:-48px !important; 
        margin-top:50px !important;   
    }
    .el-submenu__title:focus, .el-submenu__title:hover  {  
        background: #dcdee2!important; 
        width:100% !important;
        line-height:35px;
        border-left:3px solid #409eff;
    }  
    .el-menu-item:hover{  
        /* background: #2db7f5 !important;  */
        color: #fff !important; 

    }  
    .el-menu-item.is-active {  
        color: #fff !important;  
        /* background: #409EFF !important;   */
    }     
    .el-menu--collapse  {
        width:200px;
        line-height:35px;
    }  
    .el-submenu__title i {
      color: #5cadff;
    }
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }    
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}    
    .askcss{
      margin-left:1px;
      position: fixed;
      bottom:20px;
      color:#3B170B;
      border: 2px solid #c0c4cc;
      background-color: white;
      border-radius: 5px;
      z-index:999999;
    }
    .el-textarea__inner{
        resize:none;  /*el-input textarea 隐藏放大按钮 */
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
 
    ::-webkit-scrollbar-track {
        background-color: #e8eff5;
    }
 
    ::-webkit-scrollbar-thumb {
        background-color: rgb(149, 204, 248);
    }
 
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgb(149, 204, 248);
    }
 
    ::-webkit-scrollbar-thumb:active {
        background-color: rgb(149, 204, 248);
    }
    .el-table__body-wrapper {
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        }
        &::-webkit-scrollbar-track {
        background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        }
        &::-webkit-scrollbar-thumb:hover {
        background-color: #409eff;
        }
    }
</style> 
<style scoped lang="less">
 
    .divcolor{
        background-color: #5cadff;
    }

    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
  .el-icon-arrow-down {
    font-size: 10px;
  }
  .menu-div{
      width:190px;
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
      text-align:left;
      margin-left:-18px;
  }
  .submenu-div{
      width:240px;
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
  }
  .submenu-row{
      width:240px;
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
      font-weight:600;
      font-size:14px;
      float:left;
      padding-left:6px; 
      border-bottom:1px solid #ccc
  }
  .img-div{
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
      line-height:25px;
      height:25px;
  }
  .group-css{
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      width:1230px;
  }
 
  .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .card-div {
        padding: 2px;
        background:#EBEEF5; 
        text-align:middle;
        height:80px;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .card_hover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
        cursor:pointer;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #606266;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#606266;
        font-size:16px;
        font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size:10px;
  }
  .el-icon-arrow-down {
    font-size: 10px;
  }
.circle{
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      overflow:hidden;
      width:50px;
      height:50px;
      border:2px solid #409eff;
      z-index: 12;
      background-color: #fff;
  }
  .circle span{
      height:100%;
      display:block;
      font-size:14px;
      font-weight:600;
      color:red;
      vertical-align:center;
      text-align:center;
  }
</style>