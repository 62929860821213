<template>
    <div  > 
        <div style="font-size:10px;margin-top:10px;" >
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item ><el-link @click="homePage" style="color:#409EFF">{{fldObj['s_home']}}</el-link></el-breadcrumb-item>
                <el-breadcrumb-item ><span style="color:#606266">{{fldObj['s_solution']}}</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="margin-top:10px;"> 
            <el-image  :src='advertPath' fit="fill" style="width:100%;height:200px;" ></el-image>
        </div> 
        <div style="border-left:3px solid #409EFF;background:#f8f8f9; margin-top:10px;display:flex;line-height:35px; " >
                <i class="web-font" style="font-size:16px;padding-left:15px;color:#409eff"> {{fldObj['s_article']}}</i>
        </div>
        <!-- 视频与文章 -->
        <div style="display:flex; justify-content:space-between; flex-wrap:wrap">
            <span v-for="(item,index) in videoData" :key="index" > 
                <div class="img-contain">
                    <span v-if="item.IMGPATH.indexOf('.mp4')>-1">
                        <div class="video-small">
                            <video-player   class="video-player vjs-custom-skin custom-skin" 
                                            ref="videoPlayer" 
                                            :playsline="false" 
                                            :options="videoConfig[index]">
                            </video-player>
                        </div>
                    </span>
                    <span v-else>
                        <el-link :underline="false">
                            <el-image @click="pdfShow(item.PDFPATH)"  :src='item.IMGPATH' fit="scale-down" style="width:298px;height:240px; " ></el-image>
                        </el-link>  
                    </span>
                    <div class="description" :title="LANG=='EN'?item.ENAME:item.CNAME">
                        <i class="web-font" style="font-size:16px;font-weight:600">{{LANG=='EN'?item.ENAME:item.CNAME}}</i>
                    </div>
                </div>
            </span>
        </div>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">{{fldObj['s_prompt']}}</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">{{fldObj['s_sure']}}</Button>
            </div>
        </el-dialog>
    </div>  
</template>
<script>
import { paramBase,paramData ,paramData2,paramDataOrder} from '../../api/Select'
 
export default {
 
    data () {
        return {
            LANG:'', 
            advertPath:'',
            videoConfig:[], 
            videoData:[],
            promptWin:false,
            prompt:'',
            fldObj:[],
        }
    },
    mounted () {   
        this.getVideoList()
        paramDataOrder('IMGty','SOLUTEIMG' ,'v_BS_img',' order by sortby,lstseq').then((res)=>{     
            this.advertPath =res.data.result[0].IMGPATH 
        })
    
    },
    computed: {
       sumprc: function() {
            return ''
        },   
    },
    components: {
 
    },
    created () {

    },
    watch: {
 
    },
    methods: {
        //界面字段语言设置
        getField(){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55514',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =that.LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        getVideoList(){
            this.videoData=[]
            paramDataOrder('IMGty','VIDEO' ,'v_BS_img',' order by sortby,lstseq').then((res)=>{
                this.videoData =res.data.result                    
                for(let k=0;k<this.videoData.length;k++){
                    this.videoConfig.push({
                        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                        autoplay: false, // 如果true,浏览器准备好时开始回放。
                        muted: false, // 默认情况下将会消除任何音频。
                        loop: false, // 导致视频一结束就重新开始。
                        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                        language: 'zh-CN',
                        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                        sources: [{
                            type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                            src: this.videoData[k].IMGPATH // url地址 /assets/authorize/tmp4.mp4
                        }],
                        poster: '', // 你的封面地址
                        //width:'100%',
                        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                        controlBar: {
                            timeDivider: true,
                            durationDisplay: true,
                            remainingTimeDisplay: false,
                            fullscreenToggle: true // 全屏按钮
                        }
                    })
                }
            })   
        },
        pdfShow(fileUrl){
            if(fileUrl){
                //全屏显示
                let routeUrl = this.$router.resolve({
                    path: '/fullPreview',
                    query: {lang:this.LANG,fileUrl: fileUrl },
                });
                window.open(routeUrl.href, '_blank'); 
            }else{
                this.promptWin=true
                this.prompt= this.fldObj['s_upload']+' ...'
            }
        },
        homePage(){
            this.$emit('child-home')
        },
  
 
    }
}
</script>
<style lang="less">
    .video-js .vjs-tech {
    object-fit: fill;
    }
    .vjs-poster {
    background-size: cover;
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:10px !important;}
 
</style> 
<style scoped lang="less">
 
    .divcolor{
        background-color: #5cadff;
    }

    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
  .el-icon-arrow-down {
    font-size: 10px;
  }
  .menu-div{
      width:140px; 
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
  }
  .img-div{
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
      line-height:20px;
  }
  .group-css{
      display:flex;
      justify-content:flex-start;
      flex-wrap:wrap;
      width:1250px;
  }
 
  .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .card-div {
        padding: 14px;
        background:#F2F6FC; 
        text-align:middle;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#409EFF;
        font-size:16px;
        // font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }
 
    .img-contain {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-left:2px;
        border:1px solid #ccc;
        position: relative;
        width: 300px;
        height: 280px;
        overflow:hidden;
    }
    .img-contain:hover{
        border:1px solid #409eff;
    }
    .description {
        width: 100%;
        position:absolute; 
        z-index:2;
        left:0;
        bottom:0;
        text-align: center;
        line-height:35px;
        color:#3311f7;
        background-color: rgb(183, 233, 235);
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap;
    }
    .video-small {
        height: 260px;
        width: 398px;
        object-fit: fill;
        overflow:hidden;
        margin-top:10px;
        border-radius: 5px;
    }
 
</style>