<template>
    <div  >  
        <div style="font-size:10px;margin-top:10px;margin-bottom:10px;" >
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item ><el-link @click="homePage" style="color:#409EFF">{{fldObj['b_home']}}</el-link></el-breadcrumb-item>
                <el-breadcrumb-item ><span style="color:#606266">{{fldObj['b_brand']}}</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>        
        <el-row>    
            <el-col :span="24">     
                <el-carousel height='320px;'>
                    <el-carousel-item arrow="always" v-for="(item, index) in banArr" :key="index">
                        <el-image   :src='item.IMGPATH' fit="fill" style="width:100%;height:100%;" ></el-image>
                    </el-carousel-item>
                </el-carousel>
            </el-col>
        </el-row> 
        <!-- 授权代理-->
        <div style="margin-top:15px; ">
            <table  >
                <tr>
                    <td  style="width:0;border:3px solid #409EFF; "> </td>
                    <td  style="color:#303133;font-weight:600 ;font-size:1.3em;"><span style="margin-left:10px;">{{fldObj['b_agent']}}</span></td>
                    <!-- <td  style="color:#303133;float:right;font-size:10px;width:1120px;">
                        <el-link :underline="false" style="float:right;font-size:10px;"><i class="el-icon-plus"></i>查看全部</el-link>
                    </td> -->
                </tr>
            </table>
        </div>
        <div class="group-css">
            <div style="margin-top:10px;justify-content:flex-start "  >  
                <el-card :body-style="{ padding: '0px',width:'1228px',height:'250px' }"  >                    
                    <div style="display:flex; ">
                        <div style="margin:100px auto; float:left;height:250px;width:50px; ">
                            <el-link @click="leftNav" :underline="false" class="el-icon-arrow-left" style="font-size:30px;"></el-link>
                        </div>
                        <div v-for="(item, index) in certArr" :key="index"  style="width:1130px;height:270px;display:flex;justify-content:flex-start">
                            <div style="display:flex;flex-direction:column">
                                <el-tooltip  effect="light"  placement="right" style="margin:4px;cursor:pointer" popper-class="atooltip">
                                    <el-image   :src='item.IMGPATH'  fit="fill" style="width:160px;height:200px;" ></el-image>
                                    <template slot="content" >
                                        <el-image   fit="fill" style="width:480px;height:600px; text-align:center; " :src="item.IMGPATH"></el-image>        
                                    </template>
                                </el-tooltip>
                                <!-- 证书下面的描述图片 -->
                                <div v-if="item.ICONPATH" :title="item.CNAME">
                                    <el-image  :src='item.ICONPATH' fit="scale-down"  style="width:130px;height:40px;padding-top:2px;padding-bottom:2px; "></el-image>
                                </div>
                            </div>
                        </div>    
                        <div style="margin:100px auto; float:right;height:250px;width:50px;">
                            <el-link @click="rightNav" :underline="false" class="el-icon-arrow-right" style="font-size:30px;"></el-link>
                        </div> 
                    </div>
                </el-card>
            </div>
        </div>
        <!--战略合作--> 
        <div style="margin-top:15px;margin-bottom:10px; ">
            <table  >
                <tr>
                    <td  style="width:0;border:3px solid #409EFF; "> </td>
                    <td  style="color:#303133;font-weight:600 ;font-size:1.3em;"><span style="margin-left:10px;">{{fldObj['f_coop']}}</span></td>
                </tr>
            </table>
        </div>
        <div class="group-css">
            <el-card :body-style="{ padding: '0px',width:'1228px',height:'200px',display:'flex','justify-content':'space-between'  }"  > 
                <div   v-for="(item, index) in ftyArr" :key="index" style="margin-right:55px;margin-top:15px; " >        
                    <el-link :underline="false" @click="brandClick(item.SUPNO,item.CNAME,item.BANDPATH,item.SUPSENM)">
                        <el-card v-if="index<4" :body-style="{ padding: '0px',width:'260px',height:'170px' }" class="cardhover">                    
                            <el-image   :src='item.IMGPATH'  fit="scale-down" style="height:130px;padding-top:10px;" ></el-image>
                            <div style="padding: 7px;background:#EBEEF5; text-align:middle">
                                <span style="font-size:12px;color:#999">{{ LANG=='CN'?item.CNAME:item.SUPSENM}}</span> 
                            </div>
                        </el-card>
                    </el-link>
                </div>
            </el-card>
        </div>
        <!-- 热销品牌 -->
        <div style="margin-top:15px; margin-bottom:10px;">
            <table  >
                <tr>
                    <td  style="width:0;border:3px solid #409EFF; "> </td>
                    <td  style="color:#303133;font-weight:600 ;font-size:1.3em;"><span style="margin-left:10px;">{{fldObj['b_hot']}}</span></td>
                </tr>
            </table>
        </div>
        <div class="group-css">
            <el-card :body-style="{ padding: '0px',width:'1228px',height:'200px',display:'flex','justify-content':'space-between'  }"  >     
            <div style="margin-top:10px;margin-right:55px;" v-for="(item, index) in authArr" :key="index"  >
                <el-link :underline="false" @click="brandClick(item.SUPNO,item.CNAME,item.BANDPATH,item.SUPSENM)">
                    <el-card v-if="index<4" :body-style="{ padding: '0px',width:'260px',height:'170px' }" class="cardhover">                    
                        <el-image   :src='item.IMGPATH'  fit="scale-down" style="height:130px;width:258px;padding-top:10px;"  ></el-image>
                        <div style="line-height:35px;background:#EBEEF5; text-align:center">
                            <span style="font-size:12px;color:#999; ">{{LANG=='CN'?item.CNAME:item.SUPSENM}}</span> 
                        </div>
                    </el-card>
                </el-link>
            </div>
            </el-card>
        </div>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
    </div>
  
</template>
<script>
 
import { paramBase,paramData ,paramData2,paramDataOrder} from '../../api/Select'
export default {
    name:"powerList",
    data () {
        return {
            index:1,
            LANG:'',
            fldObj:{},
            certArr:[],
            banArr:[],
            authArr:[], //热销品牌
            ftyArr:[], //对接工厂
            certCount:0,
            certAll:[], //证书总数量   
            pageSize:5, //证书每页显示数量
            username:'',
            promptWin:false,
            prompt:'',
        }
    },
    mounted () {            
        //热销品牌
        paramDataOrder('imgty','HBAND','V_BS_IMG',' order by sortby,lstseq').then((res)=>{ 
            this.authArr =res.data.result
        });
        //战略合作
        paramDataOrder('imgty','COOP','V_BS_IMG',' order by sortby,lstseq').then((res)=>{ 
            this.ftyArr =res.data.result
        });
        //证书产品
        paramDataOrder('imgty','CERT','V_BS_IMG',' order by sortby,lstseq').then((res)=>{     
            this.certAll =res.data.result
            this.certCount =res.data.result.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (res.data.result.length < this.pageSize){
                this.certArr = this.certAll
            }else{
                this.certArr =this.certAll.slice(0,this.pageSize) //返回选定元素的子数组，不包含结尾元素
            }
        });
        //BANNER
        paramDataOrder('imgty','POWER','V_BS_IMG',' order by sortby,lstseq').then((res)=>{ 
            this.banArr =res.data.result
        })
 
    },
    components: {
       // fixRight,
       // mainHead,
       // mainFoot,
    },
    created () {
        this.username=this.$route.query.userno
 
    },
    watch: {
 
    },
    methods: {
        //界面字段语言设置
        getField(LANG){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55512',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        homePage(){
            this.$emit('child-powerhome')
        },
        //热销品牌事件
        brandClick(supno,name,path,ename){
            if (supno){
                let name_= this.LANG=='CN'?name:ename
                let routeUrl = this.$router.resolve({
                    path: '/hotBrand',
                    query: {lang:this.LANG,p_code:supno,p_name:name_,p_path:path },
                });
                window.open(routeUrl.href, '_self');
            }else{
                this.promptWin=true
                this.prompt='此品牌还未绑定,请联系管理员'
            }
        },
        //向左切换
        leftNav(){
            if (this.index===0){
                this.index=Math.ceil(this.certCount/this.pageSize)
            } 
            this.index--
            var _start = ( this.index - 1 ) * this.pageSize;
            var _end = this.index * this.pageSize;
            if (_start<=0){
                this.index=1
                this.certArr = this.certAll.slice(0,this.pageSize);
            }else{
                this.certArr = this.certAll.slice(_start,_end);
            }
 
        },
        //向右切换
        rightNav(){
            this.index++
            var _start = ( this.index - 1 ) * this.pageSize;
            var _end = this.index * this.pageSize;
            if (_end>this.certCount){
                this.certArr = this.certAll.slice(_start,_end);
                this.index=0
            }else{
                this.certArr = this.certAll.slice(_start,_end);
            }
        },
        //菜单事件
        childClick(val){
            console.log('main2:'+val)
 
        },
        childPower(){
            console.log('mainee:')
 
        },
 
        goBlank(path,name,param){
            let routeUrl = this.$router.resolve({
                path: path,
                query: {lang:this.LANG,pid:name,param:param},
            });
            if (param==='login'){
                window.open(routeUrl.href, '_self');
            }else if (param==='blank'){
                window.open(routeUrl.href, '_blank');
            }else{
                window.open(routeUrl.href, '_self');
            }
        },
 
 
    }
}
</script>
<style>
    .el-col:hover  {  
        background: #2d8cf0!important; 
    } 
 
    .el-submenu__title:focus, .el-submenu__title:hover  {  
        background: #dcdee2!important; 
        /* height:40px !important;  */
        width:100% !important;
    }  
    .el-menu-item:hover{  
        background: #2db7f5 !important; 
        color: #fff !important; 
        height:100% !important;  
    }  
    .el-menu-item.is-active {  
        color: #fff !important;  
        background: #409EFF !important;  
    }     
    .el-menu--collapse  {
        width:200px;
        line-height:35px;
    }  
    .el-submenu__title i {
      color: #5cadff;
    }
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }   
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    }      
</style> 
<style scoped  lang="less">
    .divcolor{
        background-color: #5cadff;
    }

    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
  .el-icon-arrow-down {
    font-size: 10px;
  }
  .menu-div{
      width:140px; 
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行
      height:40px;
      line-height:40px;
  }
  .img-div{
      text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
      overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
      white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
      line-height:20px;
  }
  .group-css{
      display:flex;
      justify-content:flex-start;
      flex-wrap:wrap;
      width:1246px;
  }
   .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .card-div {
        padding: 14px;
        background:#F2F6FC; 
        text-align:middle;
    }
    .cardhover:hover{  
        border :#409EFF 1px solid;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#409EFF;
        font-size:16px;
        // font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }
    
</style>